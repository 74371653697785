import { ref } from 'vue'
import { defineStore } from 'pinia'

import { compareAsc } from 'date-fns'

import { listSubscriptions } from '@rechargeapps/storefront-client'

import type { Subscription, SubscriptionListParams } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'
import { parseChargeDate } from '@/utils/recharge'

interface UseSubscriptionOptions {
  includeInactive?: boolean
  force?: boolean
}

const sortByChargeDate = (subs: Subscription[]) => subs.sort((a, b) => {
  if (!a.next_charge_scheduled_at || !b.next_charge_scheduled_at) {
    return -1
  }
  return compareAsc(parseChargeDate(a.next_charge_scheduled_at), parseChargeDate(b.next_charge_scheduled_at))
})

export const useSubscriptionsStore = defineStore('recharge/subscriptions', () => {
  const { useSession } = useAuthStore()

  const loading = ref(false)
  const active = ref<Subscription[]>()
  const inactive = ref<Subscription[]>()

  async function loadSubscriptions (query: SubscriptionListParams = {}) {
    let subs: Subscription[] = []
    let response = await listSubscriptions(useSession(), query)
    subs = subs.concat(response.subscriptions)
    while (response.next_cursor) {
      response = await listSubscriptions(useSession(), { cursor: response.next_cursor, limit: query.limit })
      subs = subs.concat(response.subscriptions)
    }
    return subs
  }

  const loadActive = async () => {
    loading.value = true
    active.value = await loadSubscriptions({ status: 'active', include: [ 'address', 'bundle_selections' ] })
    active.value = sortByChargeDate(active.value)
    loading.value = false
  }

  const loadInactive = async () => {
    const response = await listSubscriptions(useSession(), { status: 'cancelled', limit: 3, include: [ 'address', 'bundle_selections' ] })
    inactive.value = response.subscriptions.sort((a, b) => compareAsc(new Date(b.cancelled_at || b.created_at), new Date(a.cancelled_at || a.created_at)))
  }

  const loadAll = async () => {
    await Promise.all([
      loadActive(),
      loadInactive()
    ])
  }

  const useSubscriptions = ({ includeInactive, force }: UseSubscriptionOptions = {}) => {
    if ((!active.value && !loading.value) || force) {
      loadActive()
      if (includeInactive) {
        loadInactive()
      }
    }
    return {
      active,
      inactive
    }
  }

  const reset = () => {
    active.value = inactive.value = undefined
    loading.value = false
  }

  return {
    loading,
    active,
    inactive,
    loadActive,
    loadAll,
    useSubscriptions,
    reset
  }
})
